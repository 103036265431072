import React, { memo, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Carousel } from "antd";

import { getBannerInfo } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import ModuleTitle from "components/ModuleTitle";

import { MainWrapper } from "components/common/Wrapper";
import {
  SwiperView,
  SlideView,
  TopView,
  TopItem,
  BottomView,
  AboutUsItem,
} from "./style";
import "./index.css";

import DefaultBannerImg from "@/assets/images/home/banner.png";
import DefaultBannerImg1 from "@/assets/images/home/banner1.png";
import DefaultBannerImg2 from "@/assets/images/home/banner2.png";
import DefaultBannerImg3 from "@/assets/images/home/banner3.png";
import TopIcon1 from "@/assets/images/home/topicon1.png";
import TopIconActive1 from "@/assets/images/home/topicon1_active.png";
import TopIcon2 from "@/assets/images/home/topicon2.png";
import TopIconActive2 from "@/assets/images/home/topicon2_active.png";
import TriImg from "@/assets/images/home/tri.png";

import Btimg1 from "@/assets/images/home/btimg1.png";
import Btimg2 from "@/assets/images/home/btimg2.png";
import Btimg3 from "@/assets/images/home/btimg3.png";

export default memo(function HomePage() {
  const navigate = useNavigate();
  const { BannerList } = useContext(modalContext);
  const storageBannerInfo = getBannerInfo(1, BannerList);
  const bannerInfo =
    storageBannerInfo.length > 0
      ? storageBannerInfo
      : [
          {
            desc: "离您研究方向最近的科研团队为您服务",
            img_url: DefaultBannerImg,
            title: "基预生信",
            type: "1",
            url: "",
          },
          {
            desc: "离您研究方向最近的科研团队为您服务",
            img_url: DefaultBannerImg1,
            title: "基预生信",
            type: "1",
            url: "",
          },
          {
            desc: "离您研究方向最近的科研团队为您服务",
            img_url: DefaultBannerImg2,
            title: "基预生信",
            type: "1",
            url: "",
          },
          {
            desc: "离您研究方向最近的科研团队为您服务",
            img_url: DefaultBannerImg3,
            title: "基预生信",
            type: "1",
            url: "",
          },
        ];

  const MoreButton = ({ idx }) => {
    return (
      <div
        className="more-btn u-f-ac u-f-jsb"
        onClick={() =>
          idx === "1"
            ? navigate("/production/home")
            : navigate("/production/standardpuresequence#1")
        }
      >
        了解更多
        <img src={TriImg} alt="" />
      </div>
    );
  };
  const topItems = [
    {
      key: "1",
      title: "我要分析",
      dec: "根据您的需求，精准匹配工 程师及团队，且由工程师直 接沟通与执行",
      icon: TopIcon1,
      iconactive: TopIconActive1,
    },
    {
      key: "2",
      title: "我要测序",
      dec: "动态更新价格，努力提供市场最低价，开启测序直选通道",
      icon: TopIcon2,
      iconactive: TopIconActive2,
    },
  ];
  const btItems = [
    {
      key: "1",
      title: "运营经验",
      decs: [
        {
          idx: 1,
          subtitle: "服务科研用户300＋",
          dec: "运营“生物信息分析在线服务平台“5年，服务科研用户500+",
        },
        {
          idx: 2,
          subtitle: "数据安全",
          dec: "ISO27001信息安全管理体系",
        },
        {
          idx: 3,
          subtitle: "运维管理",
          dec: "ISO9001质量体系认证",
        },
      ],
      icon: Btimg1,
    },
    {
      key: "2",
      title: "专家团队",
      decs: [
        {
          idx: 1,
          subtitle: "生信分析专家300+",
          dec: "80%专家Science、Nature、Cell、PANS等高水平期刊杂志发表过文章博士生占比25%  5年以上经验45%",
        },
        {
          idx: 2,
          subtitle: "生信项目案例300+",
          dec: "项目包括多种物种的DNA、RNA、病毒微生物、蛋白代谢等个性化分析、定制化分析项目",
        },
      ],
      icon: Btimg2,
    },
    {
      key: "3",
      title: "运营团队",
      decs: [
        {
          idx: 1,
          subtitle: "",
          dec: "硕博团队，创始人为生物学博士，研究生导师，四川省“千人计划”领军人才",
        },
        {
          idx: 2,
          subtitle: "技术积累",
          dec: `<div style="margin-bottom:10px">生物信息分析在线服务平台</div><div style="margin-bottom:10px">生物云平台</div>测序及标准分析产品商场`,
        },
      ],
      icon: Btimg3,
    },
  ];
  return (
    <MainWrapper style={{ marginBottom: "-220px" }}>
      <SwiperView
      // data-title={bannerInfo[0].title}
      // data-dec={bannerInfo[0].desc}
      >
        <Carousel
          infinite={true}
          dots={false}
          autoplay={true}
          arrows={true}
          className="homepage-swiper"
        >
          {bannerInfo.map((item, index) => {
            return (
              <SlideView
                key={index}
                src={item.img_url}
                onClick={() => {
                  if (item.url) {
                    window.open(item.url, "_blank");
                  } else {
                    return;
                  }
                }}
              ></SlideView>
            );
          })}
        </Carousel>
      </SwiperView>
      <TopView className="u-f-jsb">
        {topItems.map((item, index) => {
          const { icon, iconactive, key, title, dec } = item;
          return (
            <TopItem icon={icon} iconactive={iconactive} key={key}>
              <div className="pri-title">{title}</div>
              <div className="u-f-jsb u-f-ac">
                <div className="main-left u-f-d u-f-jsb">
                  <div className="main-dec">{dec}</div>
                  <MoreButton idx={key} />
                </div>
                <div className="main-right"></div>
              </div>
            </TopItem>
          );
        })}
      </TopView>
      <BottomView>
        <ModuleTitle titleZh="关于我们" titleEn="About Us" />
        <div className="bottom-main">
          <div className="bg-sq"></div>
          <div className="content-ab-x u-f-jsb">
            {btItems.map((item, index) => {
              const { key, title, decs, icon } = item;
              return (
                <AboutUsItem key={key}>
                  <div className="rt-icon">
                    <img src={icon} alt="" />
                  </div>
                  <div className="pri-title">{title}</div>
                  {decs.map((item, index) => {
                    const { subtitle, idx, dec } = item;
                    return (
                      <div className="dec-item" key={idx}>
                        {!!subtitle && (
                          <div className="sub-title">{subtitle}</div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: dec }} />
                      </div>
                    );
                  })}
                </AboutUsItem>
              );
            })}
          </div>
        </div>
      </BottomView>
    </MainWrapper>
  );
});
