import styled from "styled-components";
import Productionsub1Src from "@/assets/images/common/productionsub1.png";
import Productionsub1ActiveSrc from "@/assets/images/common/productionsub1_active.png";
import Productionsub2Src from "@/assets/images/common/productionsub2.png";
import Productionsub2ActiveSrc from "@/assets/images/common/productionsub2_active.png";
import Productionsub3Src from "@/assets/images/common/productionsub3.png";
import Productionsub3ActiveSrc from "@/assets/images/common/productionsub3_active.png";
export const NavView = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-left: 4.12%;
  padding-right: 3.75%;
  z-index: 10;
  /* border: 1px solid black; */
  .logo-x {
    display: flex;
    align-items: center;
    text-indent: -999px;
    overflow: hidden;
  }
  .logo-a {
    position: absolute;
    display: block;
    width: 280px;
    height: 128px;
    background-size: contain;
    font-size: 0;
    text-indent: -999px;
    overflow: hidden;
    margin-left: -9999px;
  }
`;

export const AppLogo = styled.img`
  width: 215px;
  height: 56px;
`;
//   &.css-dev-only-do-not-override-j9bb5n {
//     &.ant-menu.ant-menu-horizontal.ant-menu-light {
//       border-bottom: none;
//       line-height: 40px;
//       font-size: 20px;
//       color: #333333;
//       & > .ant-menu-item {
//         width: 100px;
//         height: 40px;
//         text-align: center;
//         padding: 0;
//         margin: 0 1.46vw;
//         border-radius: 24px;
//         &::after {
//           border: none;
//         }
//         &:hover {
//           color: #ffffff;
//           background-color: #60569e;
//         }
//       }
//     }
//     &.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
//       color: #ffffff;
//       background-color: #60569e;
//     }
//     &.ant-menu .ant-menu-submenu-title {
//       width: 100px;
//       height: 40px;
//       text-align: center;
//       padding: 0;
//       margin: 0 1.46vw;
//       border-radius: 24px;
//       color: #333333;
//       a {
//         color: #333333;
//       }
//     }
//     &.ant-menu-horizontal .ant-menu-submenu {
//       padding: 0;
//       border: none;
//       &:hover {
//         .ant-menu-submenu-title {
//           background-color: #60569e;
//           a {
//             color: #fff;
//           }
//         }
//       }
//     }
//     &.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu::after {
//       border: none;
//     }
//     &.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
//       background-color: #60569e;
//       a {
//         color: #fff;
//       }
//     }
//     &.ant-menu-submenu-popup {
//       border-radius: 0;
//     }
//     &.ant-menu-submenu-popup
//       .ant-menu-vertical.ant-menu-sub:not([class*="-active"]) {
//       display: flex;
//     }
//     &.ant-menu-submenu-popup .ant-menu-vertical {
//       box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.16);
//       border-radius: 0px;
//     }
//     &.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
//       display: flex;
//     }
//     &.ant-menu-submenu-popup.ant-menu-submenu {
//       padding-top: 30px !important;
//     }
//   }
// `;

export const MenuView = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333;
  white-space: nowrap;

  .menu-item {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0;
    margin: 0 1.46vw;
    border-radius: 24px;
    transition: all 0.2s;
    > span {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    .dropdown-container {
      position: relative;
      .submenu-content {
        position: absolute;
        color: #595959;
        font-size: 16px;
        top: 70px;
        padding: 52px 74px 59px;
        background-color: #fff;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.16);
        display: flex;
        &.submenu-enter {
          opacity: 0;
          transform: translateY(-10px);
        }

        &.submenu-enter-active {
          opacity: 1;
          transform: translateY(0);
          transition: opacity 300ms, transform 300ms;
        }

        &.submenu-exit {
          opacity: 1;
          transform: translateY(0);
        }

        &.submenu-exit-active {
          opacity: 0;
          transform: translateY(-10px);
          transition: opacity 300ms, transform 300ms;
        }
      }
    }
  }

  .menu-item:hover,
  .menu-item.active {
    color: #fff;
    background-color: #60569e;
  }
`;
export const DropdownContent = styled.div`
  position: absolute;
  top: 17px;
  left: -37px;
  width: 525px;
  padding: 52px 39px 41px;
  box-sizing: border-box;
  color: #595959;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.16);
  display: flex;
  @media screen and (max-width: 1200px) {
    padding: 40px;
    width: 628px;
  }
  .sub-nav-list {
    position: relative;
    padding-right: 37px;
    &:not(:first-child) {
      padding-left: 37px;
    }
    &:last-child:after {
      width: 0;
    }
    &::after {
      position: absolute;
      right: 0;
      top: 0;
      content: "";
      width: 1px;
      height: 190px;
      background-color: #595959;
      opacity: 0.49;
    }
    .sub-nav-item {
      margin-bottom: 19px;
      padding-left: 37px;
      font-size: 16px;
      color: #595959;
      line-height: 16px;
      cursor: pointer;
      &:hover,
      &.active {
        color: #e960a4;
      }
    }
  }
`;

export const DropdownTitle = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 17px;
  font-size: 20px;
  color: #595959;
  line-height: 30px;
  cursor: pointer;

  .img-x {
    margin-right: 7px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    background: url(${Productionsub1Src}) no-repeat center center/cover;
    transition: all 0.3s;
  }
  &:hover,
  &.active {
    color: #e960a4;
    .img-x {
      background-image: url(${Productionsub1ActiveSrc});
    }
  }
  &[data-key="2"] {
    .img-x {
      background-image: url(${Productionsub2Src});
    }
    &:hover,
    &.active {
      .img-x {
        background-image: url(${Productionsub2ActiveSrc});
      }
    }
  }
  &[data-key="3"] {
    .img-x {
      background-image: url(${Productionsub3Src});
    }
    &:hover,
    &.active {
      .img-x {
        background-image: url(${Productionsub3ActiveSrc});
      }
    }
  }
`;
export const FloatView = styled.div`
  position: fixed;
  top: 430px;
  right: 29px;
  width: 320px;
  height: 115px;
  display: flex;
  z-index: 10;
  align-items: flex-end;
  .float-img-x {
    cursor: pointer;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .anticon.anticon-close {
    position: absolute;
    right: 0;
    top: 0;
    color: #595959;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
`;
