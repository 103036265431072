import { memo } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  font-size: 30px;
  color: #333333;
  height: 56px;

  .title-zh {
    font-size: 40px;
  }
`;

export default memo(({ titleZh, titleEn }) => {
  return (
    <Wrapper className="u-f-ajc">
      <span className="title-zh">{titleZh}</span>
      <span>｜</span>
      {titleEn}
    </Wrapper>
  );
});
