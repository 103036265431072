import styled from "styled-components";
import Bg from "@/assets/images/production/onestop_bg.png";
import ContentImg from "@/assets/images/production/onestop_content.png";
export const MainView = styled.div`
  width: 100%;
  height: 400px;
  padding-top: 20px;
  padding-bottom: 19px;
  margin-bottom: 50px;
  box-sizing: border-box;
  background: url(${Bg}) center center / cover no-repeat;
  .main-x {
    width: 1067px;
    height: 361px;
    margin: 0 auto;
    /* margin-top: 20px; */
    background: url(${ContentImg}) center center / 100% no-repeat;
  }
`;
