//判断是否为JSON
import { modalContext } from "@/constants/modalContext";
export const isJSON = (str) => {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
};
const PREFIX = "@GEN-";
// 设置指定键名的值
export const setStorage = (key, value, type = 0) => {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  if (!type) {
    // sessionStorage
    sessionStorage.setItem(`${PREFIX}${key}`, value);
  } else {
    // localStorage
    localStorage.setItem(`${PREFIX}${key}`, value);
  }
};
// 获取指定键名的值
export const getStorage = (key, type = 0) => {
  let value = !type
    ? sessionStorage.getItem(`${PREFIX}${key}`)
    : localStorage.getItem(`${PREFIX}${key}`);
  return isJSON(value) ? JSON.parse(value) : value;
};
// 清除本地存储内容
export const clearStorage = (type = 0) => {
  if (!type) {
    sessionStorage.clear();
  } else {
    localStorage.clear();
  }
};

// 获取对应页面的banner
// 1 => '首页',
// 2 => '产品中心首页',
// 3 => '产品中心标准分析',
// 4 => '产品中心定制分析',
// 5 => '合作案例',
// 6 => '加入我们',
// 7 => '关于基预',
// 8 => '联系我们',

export const getBannerInfo = (type, BannerList) => {
  const BannerListArr =
    BannerList !== null && BannerList !== undefined ? BannerList : [];
  // console.log("BannerList", BannerListArr);
  if (BannerListArr.length > 0) {
    return BannerListArr.filter((item) => Number(item.type) === type);
  } else {
    return [];
  }
};
export const validatePhone = (rule, value, callback) => {
  // 国内手机号正则，匹配13, 14, 15, 16, 17, 18, 19开头的11位数字
  const mobileRegex = /^1[3-9]\d{9}$/;
  // 座机号码正则，匹配区号3-4位，号码7-8位，区号与号码间可有"-"连接
  const landlineRegex = /^(\d{3,4}-)?\d{7,8}$/;

  if (!value) {
    callback("请输入联系方式");
  } else if (!(mobileRegex.test(value) || landlineRegex.test(value))) {
    callback("请输入有效的国内手机号或座机号码");
  } else {
    callback();
  }
};
