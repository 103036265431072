import { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";

import { Popover } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { getStorage, setStorage } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import {
  NavView,
  MenuView,
  AppLogo,
  DropdownContent,
  DropdownTitle,
  FloatView,
} from "./style";

import logo from "@/assets/images/common/logo.png";
import FloatImg from "@/assets/images/common/contact_float.png";

import "./index.css";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const localIsCloseFloat = getStorage("isCloseFloat");

  const [pathName, setPathName] = useState(location.pathname); //url地址
  const [isCloseFloat, setIsCloseFloat] = useState(localIsCloseFloat);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // 可选，平滑滚动效果
    });
    setPathName(location.pathname);
  }, [location]);

  // const ProductTypeList = getStorage("ProductTypeList");
  const { ProductTypeList } = useContext(modalContext);
  // let pureid = ProductTypeList?.find((item) => item.name === "纯测序")?.id || 5;
  let standarList = ProductTypeList || [
    { id: 1, name: "转录组" },
    { id: 2, name: "基因组" },
    { id: 3, name: "微生物组" },
    { id: 4, name: "蛋白代谢组" },
    { id: 5, name: "纯测序" },
  ];
  const subNavItems = [
    {
      key: "1",
      label: "个性化分析服务",
      path: "/production/home",
      subarr: [
        {
          title: "定制化生信分析",
          path: "/production/custombioanalysis",
        },
        {
          title: "一站式整包项目",
          path: "/production/onestopsackage",
        },
      ],
    },
    {
      key: "2",
      label: "测序检测服务",
      path: `/production/standardpuresequence#${standarList[0].id}`,
      subarr: standarList.map((item) => {
        return {
          title: item.name,
          path: `/production/standardpuresequence#${item.id}`,
        };
      }),
    },
    // {
    //   key: "3",
    //   label: "纯测序",
    //   path: `/production/standardpuresequence#${pureid}`,
    // },
  ];
  const SubNavItem = () => {
    return (
      <DropdownContent>
        {subNavItems.map((item, index) => {
          return (
            <div className="sub-nav-list" key={item.key}>
              <DropdownTitle
                data-key={item.key}
                className={`${
                  pathName === item.path ||
                  pathName + location.hash === item.path
                    ? "active"
                    : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.path === pathName) return;
                  navigate(item.path);
                }}
              >
                <div className="img-x"></div>
                {item.label}
              </DropdownTitle>
              {item?.subarr &&
                item.subarr.map((item, index) => {
                  return (
                    <div
                      className={`sub-nav-item ${
                        pathName === item.path ||
                        pathName + location.hash === item.path
                          ? "active"
                          : ""
                      }`}
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(item.path);
                      }}
                    >
                      {item.title}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </DropdownContent>
    );
  };
  const items = [
    {
      label: "首页",
      path: "/",
    },
    {
      label: (
        <Popover
          content={SubNavItem}
          overlayClassName="navpopover"
          // trigger={"click"}
        >
          产品中心
        </Popover>
      ),
      path: "/production/home",
    },
    {
      label: "合作案例",
      path: "/cooperation",
    },
    {
      label: "加入我们",
      path: "/joinus",
    },
    {
      label: "关于基预",
      path: "/about",
    },
    {
      label: "联系我们",
      path: "/concact",
    },
  ];
  const isActiveNavItem = (path) => {
    if (pathName === path) {
      return "active";
    } else {
      if (pathName.includes("production") && path.includes("production")) {
        return "active";
      }
    }
  };
  const NavItem = ({ path, label }) => {
    return (
      <Link className={`menu-item ${isActiveNavItem(path)}`} to={path}>
        {label}
      </Link>
    );
  };

  return (
    <>
      <NavView>
        <NavLink to={"/"} className="logo-x" title="基预科技">
          <AppLogo src={logo} alt="基预科技" />
        </NavLink>
        {/* 导航列表 */}
        <MenuView>
          {items.map((item) => (
            <NavItem {...item} key={item.path} />
          ))}
        </MenuView>
      </NavView>
      {!isCloseFloat && (
        <FloatView>
          <div
            className="float-img-x"
            onClick={() => {
              navigate("/concact");
            }}
          >
            <img src={FloatImg} alt="联系我们" />
          </div>

          <CloseOutlined
            onClick={() => {
              setStorage("isCloseFloat", true);
              setIsCloseFloat(true);
            }}
          />
        </FloatView>
      )}
    </>
  );
};

export default NavBar;
