import { memo } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 400px;
  background: #fff url(${(props) => props.bg}) center center / cover no-repeat;

  text-align: center;
  color: #333333;

  .common-banner-title {
    width: 1200px;
    margin-bottom: 10px;
    font-size: 60px;
    line-height: 84px;
    font-weight: bold;
  }
  .common-banner-dec {
    width: 1200px;
    font-size: 35px;
    line-height: 49px;
  }
`;

export default memo(({ info, titleStyle, decStyle }) => {
  const { title, desc, img_url, url } = info;
  return (
    <Wrapper
      bg={img_url}
      onClick={() => {
        if (!!url) {
          window.open(url, "_blank");
        } else {
          return;
        }
      }}
      className="u-f-d u-f-ajc"
    >
      {/* <div className="common-banner-title" style={titleStyle}>
        {title}
      </div>
      <div className="common-banner-dec" style={decStyle}>
        {desc}
      </div> */}
    </Wrapper>
  );
});
