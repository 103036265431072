import styled from "styled-components";

import SqImg from "@/assets/images/home/sq.png";
import SqActiveImg from "@/assets/images/home/sq_active.png";

export const SwiperView = styled.div`
  position: relative;
  width: 100%;
  height: 620px;
  /* font-size: 60px;
  color: #333333; */
  /* &::before {
    content: attr(data-title);
    position: absolute;
    transform: translateY(105px);
    width: 100%;
    text-align: center;
    z-index: 1;
  }
  &::after {
    content: attr(data-dec);
    position: absolute;
    transform: translateY(-337px);
    width: 100%;
    text-align: center;
    z-index: 1;
  } */
`;
export const SlideView = styled.div`
  width: 100%;
  height: 620px;
  background: #fff url(${(props) => props.src}) center center / cover no-repeat;
  transition: all 0.3s linear;
  &:hover {
    transform: scale(1.03);
  }
`;
export const TopView = styled.div`
  width: 1200px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  top: -220px;
  font-size: 25px;
  color: #595959;
  line-height: 36px;
  background-color: transparent;
`;
export const TopItem = styled.div`
  width: 575px;
  height: 300px;
  padding: 20px 30px 24px 50px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-radius: 15px;
  transition: all 0.3s;
  &:hover {
    color: #60569e;
    background-color: #f4f2ff;
    .main-right {
      background-image: url(${(props) => props.iconactive});
    }
    .more-btn {
      background: #60569e;
      color: #fff;
    }
    .main-left .main-dec::before {
      background-image: url(${SqActiveImg});
    }
  }
  .pri-title {
    margin-bottom: 19px;
    font-size: 35px;
    line-height: 48px;
    text-align: center;
    padding-right: 20px;
  }
  .main-left {
    width: 321px;
    height: 188px;
    .main-dec {
      position: relative;
      padding-left: 21px;
      &::before {
        position: absolute;
        left: 0;
        content: "";
        width: 15px;
        height: 15px;
        background: url(${SqImg}) center center / cover no-repeat;
      }
    }
  }
  .main-right {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    background: #fff url(${(props) => props.icon}) center center / 68.9%
      no-repeat;
  }
  .more-btn {
    width: 160px;
    height: 50px;
    padding-left: 20px;
    padding-right: 8px;
    border-radius: 50px;
    background: #595959;
    color: #f7f8fb;
    line-height: 50px;
    cursor: pointer;
    box-sizing: border-box;
    img {
      width: 20px;
      height: 25px;
    }
  }
`;
export const BottomView = styled.div`
  width: 1200px;
  margin: 0 auto;
  position: relative;
  top: -220px;
  padding: 50px 0;
  .bottom-main {
    margin-top: 50px;
    height: 430px;
    position: relative;
    .bg-sq {
      width: 100%;
      height: 285px;
      background: #f0f1f5;
    }
    .content-ab-x {
      position: absolute;
      left: 84px;
      top: 49px;
      width: 1033px;
      height: 383px;
      align-items: flex-end;
    }
  }
`;
export const AboutUsItem = styled.div`
  width: 320px;
  height: 383px;
  padding: 30px 17px 0 25px;
  background: #ffffff;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.16);
  border-radius: 36px;
  position: relative;
  font-size: 18px;
  color: #595959;
  line-height: 25px;
  box-sizing: border-box;
  .rt-icon {
    position: absolute;
    width: 97.15px;
    height: 97.15px;
    right: 0;
    top: -13px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .pri-title {
    position: relative;
    margin-bottom: 25px;
    font-size: 25px;
    color: #333333;
    line-height: 36px;
    letter-spacing: 2px;
    &::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: -5px;
      width: 40px;
      height: 5px;
      border-radius: 5px;
      background-color: #60569e;
    }
  }
  .dec-item {
    margin-bottom: 21px;
    .sub-title {
      position: relative;
      padding-left: 20px;
      margin-bottom: 10px;
      font-size: 20px;
      color: #333333;
      line-height: 28px;
      &::after {
        position: absolute;
        left: 0;
        top: 10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #333333;
        content: "";
      }
    }
  }
`;
