import styled from "styled-components";
export const Wrapper = styled.div`
  width: 900px;
  padding: 30px 56px 0 50px;
  margin-bottom: 50px;
  box-sizing: border-box;
  background: #f0f1f5;

  .right-content-top {
    padding-bottom: 33px;
  }
  .right-content-bottom {
    padding-bottom: 32px;
  }
  .title-x {
    margin-bottom: 19px;
    font-size: 25px;
    color: #333333;
    line-height: 36px;
    font-weight: bold;
    .primary-title {
      position: relative;
      padding-left: 15px;
      margin-right: 50px;
      &::after {
        position: absolute;
        left: 0;
        width: 5px;
        height: 35px;
        background: #60569e;
        content: "";
      }
    }
    .primary-price {
      padding: 0 10px;
      font-size: 20px;
      color: #ffffff;
      line-height: 28px;
      text-align: center;
      background: #60569e;
      border-radius: 15px;
    }
  }
  .primary-dec {
    margin-bottom: 10px;
    font-size: 20px;
    color: #333333;
    line-height: 24px;
  }
  .primary-plant {
    margin-bottom: 51px;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
  }
  .btn-container {
    justify-content: flex-end;
  }
  .btn-x {
    width: 114px;
    height: 30px;
    background: #333333;
    border-radius: 15px;
    font-size: 16px;
    color: #f7f8fb;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: #60569e;
    }
  }
  .css-dev-only-do-not-override-j9bb5n.ant-form .inline-view > div {
    flex: none;
  }
  .css-dev-only-do-not-override-j9bb5n.ant-btn-primary:not(:disabled):not(
      .ant-btn-disabled
    ):hover {
    background: #60569e;
  }
  .css-dev-only-do-not-override-j9bb5n.ant-btn {
    padding: 0;
    &.btn-submit {
      margin-top: 42px;
    }
  }
  .css-dev-only-do-not-override-j9bb5n.ant-btn > span {
    position: relative;
    top: -2px;
  }

  .css-j9bb5n.ant-form .inline-view > div {
    flex: none;
  }
  .css-j9bb5n.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: #60569e;
  }
  .css-j9bb5n.ant-btn {
    padding: 0;
    &.btn-submit {
      margin-top: 42px;
    }
  }
  .css-j9bb5n.ant-btn > span {
    position: relative;
    top: -2px;
  }

  .right-content-bottom {
    .css-dev-only-do-not-override-j9bb5n.ant-form-vertical
      .ant-form-item-label {
      padding-bottom: 3px;
      & > label {
        line-height: 28px;
      }
    }
    .css-dev-only-do-not-override-j9bb5n.ant-input {
      height: 45px;
      &#mobile,
      &#name {
        height: 49px;
      }
    }
    .css-dev-only-do-not-override-j9bb5n.ant-input-outlined.ant-input-disabled,
    .css-dev-only-do-not-override-j9bb5n.ant-input-outlined[disabled] {
      background-color: #fff;
      text-align: center;
      color: #333333;
      border: none;
      &#price {
        color: #60569e;
      }
    }
    .css-j9bb5n.ant-form-vertical .ant-form-item-label {
      padding-bottom: 3px;
      & > label {
        line-height: 28px;
      }
    }
    .css-j9bb5n.ant-input {
      height: 45px;
      &#mobile,
      &#name {
        height: 49px;
      }
    }
    .css-j9bb5n.ant-input-outlined.ant-input-disabled,
    .css-j9bb5n.ant-input-outlined[disabled] {
      background-color: #fff;
      text-align: center;
      color: #333333;
      border: none;
      &#price {
        color: #60569e;
      }
    }
  }
`;
export const ModalView = styled.div`
  font-size: 35px;
  color: #333333;
  line-height: 49px;
  text-align: center;
  img {
    width: 128px;
    height: 128px;

    margin: 50px 0;
  }
  .title-x {
    margin-bottom: 60px;
    font-weight: bold;
    font-size: 80px;
    color: #60569e;
    line-height: 112px;
  }
  .dec-x {
    margin-top: 50px;
    font-size: 40px;
    color: #333333;
    line-height: 56px;
  }
`;
