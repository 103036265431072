import React, { memo, useContext, useEffect } from "react";

import CommonBanner from "components/CommonBanner";
import ModuleTitle from "components/ModuleTitle";

import { getBannerInfo } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import { MainWrapper } from "components/common/Wrapper";
import { TopWrapper, PeopleItem, BottomWrapper } from "./style";

import DefaultBannerImg from "@/assets/images/about/banner.png";
import People1 from "@/assets/images/about/people1.png";
import People2 from "@/assets/images/about/people2.png";
import People3 from "@/assets/images/about/people3.png";
import Subtitle1 from "@/assets/images/about/subtitle1.png";
import Subtitle2 from "@/assets/images/about/subtitle2.png";
import Subtitle3 from "@/assets/images/about/subtitle3.png";
import PeopleBg from "@/assets/images/about/bg1.png";
import PeopleBg1 from "@/assets/images/about/bg2.png";
import { useLocation } from "react-router-dom";

export default memo(function ConcactUsPage() {
  const { BannerList } = useContext(modalContext);

  const storageBannerInfo = getBannerInfo(7, BannerList);
  const bannerInfo =
    storageBannerInfo.length > 0
      ? storageBannerInfo
      : [
          {
            desc: "基预生信平台致力于成为国内质量最高的一站式科研服务平台。平台通过整合国内外高校、科研院所、检测机构以及生物技术企业的科研资源，拥有全国最具规模且专业程度最高的生物信息科学家/工程师队伍，以及最具性价比的科研服务价格。可轻松实现客户项目的精准匹配，让离你研究方向最近的科研团队为你服务。平台业务贯通生命科学研究全领域，为全国乃至全球的科学家和企业家提供高质量的科研服务。",
            img_url: DefaultBannerImg,
            title: "成都基预科技有限公司",
            type: "7",
            url: "",
          },
        ];

  const infoArr = [
    {
      id: 1,
      icon: People1,
      dec: "国家卫健委流动人口中心健康服务与技术推广学会副秘书长，成都市高新区C类认定人才。2016年成都市高新区第一批 “高新人才获得者“，入选成都市2017年度“创业新星计划”，并作为项目负责人承担2018年成都市重大科技创新及应用示范项目。在PNAS、Nature Communications等国际顶级学术期刊发表论文8篇，申请发明专利9项，实用新型专利1项。",
      title: "白丽军 董事长",
      subtitle: Subtitle1,
      bg: PeopleBg,
    },
    {
      id: 2,
      icon: People2,
      dec: "-德国马克斯·普朗克研究所（Max Planck Institute，MPI）生物信息学博士拥有基因组测序与数据分析8年研发工作经验，在eLife,PNAS,the plant journal等国际顶级学术期刊发表过10余篇论文。拥有2项发明专利，10项软件著作权，并主持过四川省科技厅服务示范项⽬。是高层次海外留学回国人才，成都市高新区金熊猫人才。",
      title: "凌之浩   总经理",
      subtitle: Subtitle2,
      bg: PeopleBg1,
    },
    {
      id: 3,
      icon: People3,
      dec: `精通基因组学、生物信息学和算法研究开发，具有丰富的数字化研发经验和深度学习算法研发落地经验。拥有高效的学习能力，涵盖广泛的知识领域。`,
      title: "魏彬   生信分析总监",
      subtitle: Subtitle3,
      bg: PeopleBg,
    },
  ];
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, [location]);
  return (
    <MainWrapper>
      <CommonBanner
        info={bannerInfo[0]}
        titleStyle={{
          fontSize: "40px",
          lineHeight: "56px",
          marginBottom: "12px",
        }}
        decStyle={{ fontSize: "20px", lineHeight: "38px", textAlign: "left" }}
      ></CommonBanner>
      <TopWrapper>
        <ModuleTitle titleZh="团队介绍" titleEn="Team Introduction" />
        <div className="pridec-x">
          高水平的分析服务必然需要深厚且敏锐的技术经验来支撑，基预的专家团队具备顶尖水平。
        </div>
        <div className="peoples-dec-x u-f-jsb">
          {infoArr.map((item) => {
            const { id, icon, dec, title, bg, subtitle } = item;
            return (
              <PeopleItem bg={bg} className="u-f-d u-f-ac" key={id}>
                <div className="img-x u-f-ajc">
                  <img src={icon} alt="" />
                </div>
                <div className="prititle-x">{title}</div>
                <img className="subtitle-x" alt="" src={subtitle} />
                <div
                  className="dec-x"
                  dangerouslySetInnerHTML={{ __html: dec }}
                ></div>
              </PeopleItem>
            );
          })}
        </div>
      </TopWrapper>
      <BottomWrapper>
        <div className="bottom-x" id="bottom">
          <ModuleTitle titleZh="工程师团队" titleEn="Team of Engineers" />
          <div className="dec-x">
            经过基预专家团队的测试评估，近300个工程师团队及个人被按照专业方向、项目经验进行分类管理。在用户涉及不同领域或专业的不同定制需求下，基预能够快速根据工程师经验进行项目任务的精准匹配，最大化效率的同时为项目完成质量提供有力保障。
          </div>
        </div>
      </BottomWrapper>
    </MainWrapper>
  );
});
