import React, {
  memo,
  useRef,
  useEffect,
  useState,
  Fragment,
  useContext,
} from "react";

import CommonBanner from "components/CommonBanner";
import ModuleTitle from "components/ModuleTitle";
import CustomCommonBottom from "../components/CustomCommonBottom";
import { MainWrapper } from "components/common/Wrapper";

import { getBannerInfo } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import { Wrapper, MainView, MainItem } from "./style";

import DefaultBannerImg from "@/assets/images/production/banner.png";

import BtIcon1 from "@/assets/images/production/custom_icon1.png";
import BtIcon2 from "@/assets/images/production/custom_icon2.png";
import BtIcon3 from "@/assets/images/production/custom_icon3.png";
import BtIcon4 from "@/assets/images/production/custom_icon4.png";
import BtIcon5 from "@/assets/images/production/custom_icon5.png";

export default memo(function ProductionCustombioanalysis() {
  const { BannerList } = useContext(modalContext);
  const storageBannerInfo = getBannerInfo(4, BannerList);

  const bannerInfo =
    storageBannerInfo.length > 0
      ? storageBannerInfo
      : [
          {
            desc: "助力科研价值深度挖掘",
            img_url: DefaultBannerImg,
            title: "产品中心",
            type: "3",
            url: "",
          },
        ];

  const infoArr = [
    {
      id: 1,
      dec: "明确<br/>分析需求",
      icon: BtIcon1,
    },
    {
      id: 2,
      dec: "科研团队<br/>内部评估",
      icon: BtIcon2,
    },
    {
      id: 3,
      dec: "匹配工程师",
      icon: BtIcon3,
    },
    {
      id: 4,
      dec: "确认合同",
      icon: BtIcon4,
    },
    {
      id: 5,
      dec: "项目执行",
      icon: BtIcon5,
    },
  ];
  return (
    <MainWrapper>
      <CommonBanner
        info={bannerInfo[0]}
        titleStyle={{
          marginBottom: "20px",
        }}
        decStyle={{ fontSize: "40px", lineHeight: "56px" }}
      ></CommonBanner>
      <Wrapper>
        <ModuleTitle
          titleZh="定制化生信分析"
          titleEn="Customized Bioinformatics analysis"
        />
        <div className="sub-dec">
          提出您的具体分析要求，基预团队进行梳理与分析，为您精准匹配分析工程师，高效高质量的完成分析任务。
        </div>
      </Wrapper>
      <MainView>
        <div className="main-x u-f-jsb">
          {infoArr.map((item, index) => {
            const { id, dec, icon } = item;
            return (
              <Fragment key={id}>
                <MainItem className="u-f-d u-f-ac">
                  <img
                    src={icon}
                    alt=""
                    style={
                      (index !== 0 || index !== 1) && { marginBottom: "58px" }
                    }
                  />
                  <div
                    className="dec"
                    dangerouslySetInnerHTML={{ __html: dec }}
                  ></div>
                </MainItem>
                {index !== 4 && <div className="main-item-icon"></div>}
              </Fragment>
            );
          })}
        </div>
      </MainView>
      <CustomCommonBottom />
    </MainWrapper>
  );
});
