import React, { memo, useState, useContext } from "react";

import CommonBanner from "components/CommonBanner";
import ModalWrapper from "components/ModalWrapper";

import { getBannerInfo } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import { MainWrapper } from "components/common/Wrapper";
import { Wrapper, MoreCooprationView } from "./style";

import DefaultBannerImg from "@/assets/images/cooperation/banner.png";
import Icon1 from "@/assets/images/cooperation/icon1.png";
import Icon2 from "@/assets/images/cooperation/icon2.png";
import Icon3 from "@/assets/images/cooperation/icon3.png";
import Icon4 from "@/assets/images/cooperation/icon4.png";

import Logo from "@/assets/images/common/logo.png";
import iconImg1 from "@/assets/images/common/icon_tel.png";
import iconImg2 from "@/assets/images/common/icon_adr.png";
import iconImg3 from "@/assets/images/common/icon_web.png";

export default memo(function CooperationPage() {
  const { BannerList } = useContext(modalContext);
  const storageBannerInfo = getBannerInfo(5, BannerList);
  const bannerInfo =
    storageBannerInfo.length > 0
      ? storageBannerInfo
      : [
          {
            desc: "让服务匹配更精准，让科学研究更轻松",
            img_url: DefaultBannerImg,
            title: "合作案例",
            type: "5",
            url: "",
          },
        ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const infoArr = [
    {
      id: 1,
      icon: Icon1,
      dec: `转录组揭示某植物种子中的代谢通路机理。
      <br />
      论文发表期刊：BMC Plant Biology （IF 5.3）
      <br />
      https://doi.org/10.1186/s12870-021-02921-x`,
      title: "某农业高校合作展示",
      tag: "转录组",
    },
    {
      id: 2,
      icon: Icon2,
      dec: `某作物高质量基因组组装。<br/>论文发表期刊：Scientific Data （IF 9.8）<br/>https://doi.org/10.1038/s41597-020-0480-0`,
      title: "某国家重点实验室合作展示",
      tag: "基因组",
    },
    {
      id: 3,
      icon: Icon3,
      dec: `通过GWAS挖掘肺癌关联性变异位点，并通过机器学习，构建肺癌预测模型。<br/>论文发表期刊：Clinical Oncology （IF 4.9）<br/>https://doi.org/10.1016/j.clon.2023.08.010`,
      title: "某三甲医院合作案例",
      tag: "机器学习",
    },
    {
      id: 4,
      icon: Icon4,
      dec: `基于群体遗传学方法，探究某作物在我国种群分布的进化路径，揭示在基因组层面<br/>与环境因子强关联的区域，与核心调控基因。<br/>目标期刊：Nature Communications（IF 16.6）`,
      title: "某重点高校合作案例",
      tag: "基因组重测序 ",
    },
  ];

  const subdecArr = [
    {
      dec: "028-89892883",
      icon: iconImg1,
      title: "联系电话",
      size: ["48px", "48px", "40px"],
    },
    {
      dec: "四川省成都市高新区孵化园1号楼A栋2楼21号",
      icon: iconImg2,
      title: "地址",
      size: ["41px", "44px", "41px"],
    },
    {
      dec: "www.genepre.com",
      icon: iconImg3,
      title: "网址",
      size: ["50px", "41px", "44px"],
    },
  ];
  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <MainWrapper>
      <CommonBanner
        info={bannerInfo[0]}
        titleStyle={{
          marginBottom: "20px",
        }}
      ></CommonBanner>
      <Wrapper>
        {infoArr.map((item, index) => {
          const { id, tag, title, dec, icon } = item;
          return (
            <div className="coopration-item u-f" key={id}>
              <img src={icon} alt="" />
              <div className="coopration-content-x">
                <div className="primary-title-x u-f-ac">
                  <div className="tag-x">{tag}</div>
                  {title}
                </div>
                <div
                  className="dec-x"
                  dangerouslySetInnerHTML={{ __html: dec }}
                ></div>
              </div>
            </div>
          );
        })}
        <div className="coopration-btn-x u-f">
          <div
            className="coopration-btn"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            了解更多
          </div>
        </div>
      </Wrapper>
      <ModalWrapper open={isModalOpen} onOk={handleOk} onCancel={handleOk}>
        <MoreCooprationView className="u-f-d u-f-ac">
          <img src={Logo} alt="" className="logo-x" />
          <div className="primary-title">想了解更多 请与我们联系</div>
          <div className="info-x u-f-jsb">
            {subdecArr.map((item) => {
              const { dec, icon, title, size } = item;
              return (
                <div className="info-item u-f-d u-f-ac" key={title}>
                  <img
                    src={icon}
                    alt=""
                    style={{
                      width: size[0],
                      height: size[1],
                      marginBottom: size[2],
                    }}
                  />
                  <div className="title-x">{title}</div>
                  <div>{dec}</div>
                </div>
              );
            })}
          </div>
        </MoreCooprationView>
      </ModalWrapper>
    </MainWrapper>
  );
});
