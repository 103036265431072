import React, { memo, useContext } from "react";

import CommonBanner from "components/CommonBanner";

import { getBannerInfo } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import { MainWrapper } from "components/common/Wrapper";
import { Wrapper } from "./style";

import DefaultBannerImg from "@/assets/images/concact/banner.png";
import Icon1 from "@/assets/images/concact/icon1.png";
import Icon2 from "@/assets/images/concact/icon2.png";
import Icon3 from "@/assets/images/concact/icon3.png";
import TelImg from "@/assets/images/common/icon_tel.png";

export default memo(function ConcactUsPage() {
  const { BannerList } = useContext(modalContext);
  const storageBannerInfo = getBannerInfo(8, BannerList);
  const bannerInfo =
    storageBannerInfo.length > 0
      ? storageBannerInfo
      : [
          {
            desc: "欢迎随时与我们联系 我们将竭诚为您解答疑问、提供服务",
            img_url: DefaultBannerImg,
            title: "联系我们",
            type: "8",
            url: "",
          },
        ];

  const infoArr = [
    {
      id: 1,
      icon: Icon1,
      dec: "联系电话：028-89892883",
      title: "项目部",
      tel: "电话：191 8084 5212",
      size: ["80px", "80px"],
    },
    {
      id: 2,
      icon: Icon2,
      dec: "邮箱：project@genepre.com",
      title: "生信部",
      tel: "电话：187 8226 4043",
      size: ["75px", "80px"],
    },
    {
      id: 3,
      icon: Icon3,
      dec: `<div style="margin-bottom:20px">基预生信</div><div>助力科研价值深度挖掘</div>`,
      title: "市场部",
      tel: "电话：186 8379 2023",
      size: ["98px", "86px"],
    },
  ];

  return (
    <MainWrapper>
      <CommonBanner info={bannerInfo[0]}></CommonBanner>
      <Wrapper className="u-f-jsb">
        {infoArr.map((item) => {
          const { icon, dec, title, tel, size } = item;
          return (
            <div className="info-item u-f-d u-f-jsb u-f-ac" key={title}>
              <div className="img-x u-f-ajc">
                <img
                  src={icon}
                  alt=""
                  style={{ width: size[0], height: size[1] }}
                />
              </div>
              <div
                className="square-x"
                dangerouslySetInnerHTML={{ __html: dec }}
              ></div>
              <div className="primary-title">{title}</div>
              <div className="tel-x u-f-ajc">
                <img src={TelImg} alt="" />
                {tel}
              </div>
            </div>
          );
        })}
      </Wrapper>
    </MainWrapper>
  );
});
