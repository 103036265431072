import styled from "styled-components";
import DevBg from "@/assets/images/joinus/dev_bg.png";
import IntroBg from "@/assets/images/joinus/intro_bg.png";

export const TopWrapper = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
  box-sizing: border-box;
  .devmain-x {
    width: 1200px;
    height: 300px;
    margin-top: 50px;
    padding: 50px 64px 48px;
    box-sizing: border-box;
    background: #f7f8fb url(${DevBg}) center center / cover no-repeat;
    font-size: 25px;
    color: #333333;
    line-height: 38px;
  }
`;
export const BottomWrapper = styled.div`
  width: 100%;
  height: 686px;
  padding-top: 92px;
  box-sizing: border-box;
  background: rgba(240, 241, 245, 0.8) url(${IntroBg}) center center / cover
    no-repeat;
  .intromain-x {
    width: 1200px;
    height: 432px;
    margin: 0 auto;
    margin-top: 44px;
    .intromain-item {
      width: 350px;
      height: 100%;
      .top-x {
        width: 350px;
        height: 350px;
        background: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        img {
          margin-bottom: 37px;
        }
        .title-x {
          margin-bottom: 37px;
          font-weight: bold;
          font-size: 25px;
          color: #333333;
          line-height: 36px;
        }
        .dec-x {
          font-size: 20px;
          color: #333333;
          line-height: 30px;
          text-align: center;
        }
      }
      .btn-x {
        width: 114px;
        height: 32px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(36, 32, 57, 0.3);
        border-radius: 16px;
        text-align: center;
        line-height: 32px;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          background: #60569e;
          color: #fff;
        }
      }
    }
  }
`;
export const JoinUsForm = styled.div`
  width: 100%;
  height: 1005;
  padding: 49px 13px 0 28px;
  box-sizing: border-box;
  .logo-x {
    width: 347px;
    height: 90px;
    margin-bottom: 50px;
  }
  .form-x {
    width: 559px;
    height: 457px;
    padding: 35px 27px 31px 19px;
    box-sizing: border-box;
    background: rgba(244, 242, 255, 0.45);
  }
`;
