import styled from "styled-components";
import SqImg from "@/assets/images/home/sq.png";
import SqActiveImg from "@/assets/images/home/sq_active.png";

export const Wrapper = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 51px;
  .top-dec {
    margin-bottom: 50px;
    font-size: 25px;
    color: #333333;
    line-height: 33px;
    text-align: center;
  }
  .customized-analysis-x {
    width: 100%;
    height: 350px;
    margin: 50px 0;
  }
  .bt-x {
    margin-top: 50px;
  }
`;
export const CustomizedItem = styled.div`
  width: 575px;
  height: 350px;
  padding-left: 50px;
  padding-top: 49px;
  box-sizing: border-box;
  background: url(${(props) => props.icon}) center center / cover no-repeat;
  transition: all 0.3s;
  font-weight: 400;
  font-size: 25px;
  color: #595959;
  line-height: 36px;
  &:hover {
    background-image: url(${(props) => props.iconactive});
    color: #60569e;
    .title-x {
      color: #60569e;
    }
    .dec-x::before {
      background-image: url(${SqActiveImg});
    }
    .more-btn {
      background: #60569e;
      color: #fff;
    }
  }
  .title-x {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 30px;
    color: #333333;
    line-height: 42px;
  }
  .dec-x {
    margin-bottom: 64px;
    position: relative;
    padding-left: 19px;
    &::before {
      position: absolute;
      left: 0;
      top: 11px;
      width: 12px;
      height: 12px;
      background: url(${SqImg}) center center / cover no-repeat;
      content: "";
    }
  }
  .more-btn {
    width: 160px;
    height: 50px;
    padding-left: 19px;
    padding-right: 8px;
    border-radius: 5px;
    background: #333333;
    color: #f7f8fb;
    line-height: 50px;
    cursor: pointer;
    box-sizing: border-box;
    img {
      width: 20px;
      height: 25px;
    }
  }
`;
export const StandardItem = styled.div`
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: #60569e;
    .img-x {
      background-image: url(${(props) => props.iconactive});
    }
  }
  .img-x {
    width: 220px;
    height: 220px;
    margin-bottom: 46px;
    border-radius: 50%;
    background: #f5f5f5 url(${(props) => props.icon}) center center / 84%
      no-repeat;
  }
`;
