import styled from "styled-components";

export const Wrapper = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
  .main-content {
    width: 100%;
    margin-top: 50px;
    .right-content-x {
      width: 904px;
      height: calc(100vh - 150px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #cccccc;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        /* background: #cccccc; */
      }
    }
    .standardcollapse-left-content {
      width: 250px;
      margin-right: 46px;
    }
  }
`;
