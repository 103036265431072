import styled from "styled-components";
import BtBg from "@/assets/images/about/bt_bg.png";

export const TopWrapper = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 99px;
  .pridec-x {
    margin-top: 41px;
    margin-bottom: 36px;
    font-size: 25px;
    color: #333333;
    line-height: 38px;
    text-align: center;
  }
  .peoples-dec-x {
    width: 100%;
    height: 500px;
  }
`;
export const PeopleItem = styled.div`
  width: 360px;
  height: 100%;
  padding: 30px 48px 0 48px;
  box-sizing: border-box;
  background: #fff url(${(props) => props.bg}) center top / 100% auto no-repeat;
  border-radius: 20px;
  box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.16);
  .img-x {
    width: 142px;
    height: 142px;
    margin-bottom: 9px;
    border: 3px solid rgba(96, 86, 158, 0.77);
    border-radius: 50%;
    box-sizing: border-box;
    img {
      width: 130px;
      height: 130px;
    }
  }

  .prititle-x {
    margin-bottom: 11px;
    font-weight: bold;
    font-size: 30px;
    color: #333333;
    line-height: 42px;
  }
  .subtitle-x {
    width: 100%;
    height: auto;
    margin-bottom: 9px;
  }
  .dec-x {
    font-size: 16px;
    color: #333333;
    line-height: 20px;
  }
`;
export const BottomWrapper = styled.div`
  width: 100%;
  height: 293px;
  background: #fff url(${BtBg}) center center / cover no-repeat;
  .bottom-x {
    width: 1144px;
    margin: 0 auto;
    font-size: 25px;
    color: #333333;
    line-height: 38px;
    text-align: center;
    .dec-x {
      margin-top: 41px;
    }
  }
`;
