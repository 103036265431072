import React, { memo, useRef, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CommonBanner from "components/CommonBanner";
import ModuleTitle from "components/ModuleTitle";
import CustomCommonBottom from "../components/CustomCommonBottom";

import { MainWrapper } from "components/common/Wrapper";

import { Wrapper } from "../ProductionCustombioanalysis/style";

import { getBannerInfo } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import { MainView } from "./style";
import DefaultBannerImg from "@/assets/images/production/banner2.png";
export default memo(function ProductionOneStopSackage() {
  const navigate = useNavigate();
  const { BannerList } = useContext(modalContext);

  const storageBannerInfo = getBannerInfo(4, BannerList);

  const bannerInfo =
    storageBannerInfo.length > 0
      ? storageBannerInfo
      : [
          {
            desc: "助力科研价值深度挖掘",
            img_url: DefaultBannerImg,
            title: "产品中心",
            type: "3",
            url: "",
          },
        ];
  return (
    <MainWrapper>
      <CommonBanner
        info={bannerInfo[0]}
        titleStyle={{
          marginBottom: "20px",
        }}
        decStyle={{ fontSize: "40px", lineHeight: "56px" }}
      ></CommonBanner>

      <Wrapper>
        <ModuleTitle
          titleZh="一站式整包项目"
          titleEn="One-stop Turnkey project"
        />
        <div className="sub-dec">
          只需您提出项目思路，基预团队进行梳理与交流，为您精准匹配工程师团队（导师+执行工程师），通过严格的项目管理，最终保障项目高质量产出
        </div>
      </Wrapper>
      <MainView>
        <div className="main-x u-f-ac"></div>
      </MainView>
      <CustomCommonBottom />
    </MainWrapper>
  );
});
