import React, { useState } from "react";
import { Input, Button } from "antd";
import styled from "styled-components";
const Wrapper = styled.div`
  position: relative;
  .btn-x1 {
    position: absolute;
    right: 0;
    width: 45px;
    height: 45px;
    background: #e960a4;
    color: #fff;
    font-size: 28px;
    border-radius: 0;
    &.btn-minus {
      right: unset;
      left: 0;
      z-index: 1;
    }
  }
  .css-dev-only-do-not-override-j9bb5n.ant-btn-primary.btn-x1:disabled {
    background: #d8d9dc;
    color: #333333;
    border: none;
  }
  .css-dev-only-do-not-override-j9bb5n.ant-btn-primary.btn-x1:not(
      :disabled
    ):not(.ant-btn-disabled):hover {
    background: #e960a4;
  }

  .css-j9bb5n.ant-btn-primary.btn-x1:disabled {
    background: #d8d9dc;
    color: #333333;
    border: none;
  }
  .css-j9bb5n.ant-btn-primary.btn-x1:not(:disabled):not(
      .ant-btn-disabled
    ):hover {
    background: #e960a4;
  }
`;
const CustomNumericInput = (props) => {
  const {
    min = 0,
    max = Infinity,
    step = 50,
    defaultValue = 51,
    onChange,
  } = props;
  const [value, setValue] = useState(defaultValue);

  // 加法操作
  const handleIncrement = () => {
    if (value < max) {
      setValue(Math.min(value + step, max));
      onChange && onChange(value + step);
    }
  };

  // 减法操作
  const handleDecrement = () => {
    if (value > min) {
      setValue(Math.max(value - step, min));
      onChange && onChange(value - step);
    }
  };

  // 输入值变更时的处理
  const handleChange = (e) => {
    const num = parseFloat(e.target.value);
    if (!isNaN(num)) {
      setValue(Math.min(Math.max(num, min), max));
      onChange && onChange(num);
    } else {
      setValue(defaultValue);
    }
  };

  return (
    <Wrapper>
      <Button
        className="btn-x1 btn-minus"
        type="primary"
        onClick={handleDecrement}
        disabled={value <= min}
      >
        -
      </Button>
      <Input
        type="number"
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
        style={{ width: "220px", textAlign: "center" }}
      />
      <Button
        className="btn-x1 btn-plus"
        type="primary"
        onClick={handleIncrement}
        disabled={value >= max}
      >
        +
      </Button>
    </Wrapper>
  );
};

export default CustomNumericInput;
