import React, { memo, useState, useContext, useEffect } from "react";

import { Button, Form, Input, Select, message } from "antd";

import CommonBanner from "components/CommonBanner";
import ModuleTitle from "components/ModuleTitle";
import ModalWrapper from "components/ModalWrapper";

import { getBannerInfo, validatePhone } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import apis from "@/services/apis";
import { post } from "@/services/request";

import { MainWrapper } from "components/common/Wrapper";
import { TopWrapper, BottomWrapper, JoinUsForm } from "./style";

import DefaultBannerImg from "@/assets/images/joinus/banner.png";
import Icon1 from "@/assets/images/joinus/icon1.png";
import Icon2 from "@/assets/images/joinus/icon2.png";
import Icon3 from "@/assets/images/joinus/icon3.png";
import Logo from "@/assets/images/common/logo.png";
import { useLocation } from "react-router-dom";

const { Option } = Select;

export default memo(function ConcactUsPage({ route }) {
  const [form] = Form.useForm();
  const { BannerList } = useContext(modalContext);
  const storageBannerInfo = getBannerInfo(6, BannerList);
  const bannerInfo =
    storageBannerInfo.length > 0
      ? storageBannerInfo
      : [
          {
            desc: "成都基预科技有限公司是一家专注前沿生物技术、致力于用互联网加速生命科学研究，推动前沿基因科技在人类健康和农业领域的应用的高新技术企业",
            img_url: DefaultBannerImg,
            title: "加入我们",
            type: "6",
            url: "",
          },
        ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const infoArr = [
    {
      id: 1,
      icon: Icon1,
      dec: `参与基预用户各类定制<br />分析服务项目`,
      title: "工程师团队",
      size: ["43px", "90px", "90px"],
    },
    {
      id: 2,
      icon: Icon2,
      dec: `解答用户问题   协调和保<br/>障服务项目的高质量完成`,
      title: "项目管理团队",
      size: ["43px", "115px", "90px"],
    },
    {
      id: 3,
      icon: Icon3,
      dec: `推动基预品牌的发展<br/>和壮大`,
      title: "市场推广团队",
      size: ["64px", "117px", "69px"],
    },
  ];
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    if (loading) return;
    // console.log(values);
    setLoading(true);
    try {
      const res = await post(apis.postApplyOffer, { ...values });

      if (res?.code === 1) {
        message.success(res?.msg || "请求成功");
        handleOk();
      } else {
        message.error(res?.msg || "请求出错");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, [location]);
  return (
    <MainWrapper>
      <CommonBanner
        info={bannerInfo[0]}
        titleStyle={{
          marginBottom: "15px",
        }}
        decStyle={{ fontSize: "30px", lineHeight: "42px" }}
      ></CommonBanner>
      <TopWrapper>
        <ModuleTitle titleZh="基预发展" titleEn="Development" />
        <div className="devmain-x">
          基预生信平台致力于成为国内质量最高的一站式科研服务平台。平台通过整合国内外高校、科研院所、检测机构以及生物技术企业的科研资源，拥有全国最具规模且专业程度最高的生物信息科学家/工程师队伍，以及最具性价比的科研服务价格。可轻松实现客户项目的精准匹配，让离你研究方向最近的科研团队为你服务。平台业务贯通生命科学研究全领域，为全国乃至全球的科学家和企业家提供高质量的科研服务。
        </div>
      </TopWrapper>
      <BottomWrapper>
        <ModuleTitle titleZh="团队介绍" titleEn="Team Introduction" />
        <div className="intromain-x u-f-jsb" id="intromain">
          {infoArr.map((item, index) => {
            const { id, size, title, dec, icon } = item;
            return (
              <div className="intromain-item u-f-d u-f-jsb u-f-ac" key={id}>
                <div
                  className="top-x u-f-d u-f-ac"
                  style={{ paddingTop: size[0] }}
                >
                  <img
                    src={icon}
                    alt=""
                    style={{ width: size[1], height: size[2] }}
                  />
                  <div className="title-x">{title}</div>
                  <div
                    className="dec-x"
                    dangerouslySetInnerHTML={{ __html: dec }}
                  ></div>
                </div>
                <div
                  className="btn-x"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  点击加入
                </div>
              </div>
            );
          })}
        </div>
      </BottomWrapper>
      <ModalWrapper open={isModalOpen} onOk={handleOk} onCancel={handleOk}>
        <JoinUsForm className="u-f-d u-f-ac">
          <img src={Logo} alt="" className="logo-x" />
          <div className="form-x">
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <div className="u-f inline-view">
                <Form.Item
                  style={{ marginRight: "32px" }}
                  label="姓名"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "请输入姓名",
                    },
                  ]}
                >
                  <Input placeholder="请输入姓名（必填）" />
                </Form.Item>
                <Form.Item
                  name="gender"
                  label="性别"
                  rules={[
                    {
                      required: true,
                      message: "请选择性别",
                    },
                  ]}
                >
                  <Select placeholder="请选择" allowClear>
                    <Option value="male">男</Option>
                    <Option value="female">女</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                label="联系方式"
                name="mobile"
                rules={[
                  {
                    required: true,
                    validator: validatePhone,
                  },
                ]}
              >
                <Input placeholder="请输入联系方式（必填）" />
              </Form.Item>
              <Form.Item
                label="感兴趣岗位"
                name="position"
                rules={[
                  {
                    required: true,
                    message: "请选择感兴趣岗位",
                  },
                ]}
              >
                <Select placeholder="请选择" allowClear>
                  <Option value="1">工程师</Option>
                  <Option value="2">项目管理</Option>
                  <Option value="3">市场推广</Option>
                </Select>
              </Form.Item>
              <div className="u-f-jc">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                  >
                    提交信息
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </JoinUsForm>
      </ModalWrapper>
    </MainWrapper>
  );
});
