import styled from "styled-components";

export const Wrapper = styled.div`
  width: 1200px;
  height: 540px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 52px;
  .info-item {
    width: 380px;
    height: 100%;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
    .img-x {
      width: 120px;
      height: 120px;
      background: #f0f1f5;
      border-radius: 50%;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .square-x {
      width: 380px;
      height: 200px;
      padding-top: 50px;
      background: #f7f8fb;
      border-radius: 15px;
      text-align: center;

      box-sizing: border-box;
      > div {
        text-align: center;
      }
    }
    .primary-title {
      font-size: 30px;
      color: #333333;
      line-height: 42px;
      text-align: center;
    }
    .tel-x img {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  }
`;
