import React, { memo, useRef, useEffect, useState, useContext } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Collapse, Checkbox, message, Empty } from "antd";

import CommonBanner from "components/CommonBanner";
import ModuleTitle from "components/ModuleTitle";
import { MainWrapper } from "components/common/Wrapper";
import RightItem from "./components/RightItem";

import { getBannerInfo, getStorage, setStorage } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import apis from "@/services/apis";
import { get } from "@/services/request";

import { Wrapper } from "./style";
import "./index.css";

import DefaultBannerImg from "@/assets/images/production/banner.png";

export default memo(function ProductionStandardPureSequence() {
  const rightContentRef = useRef(null);
  const location = useLocation();

  const navigate = useNavigate();
  const { BannerList, ProductTypeList, StandardAnalysisInfo } =
    useContext(modalContext);
  const storageBannerInfo = getBannerInfo(3, BannerList);
  const bannerInfo =
    storageBannerInfo.length > 0
      ? storageBannerInfo
      : [
          {
            desc: "助力科研价值深度挖掘",
            img_url: DefaultBannerImg,
            title: "基预生信",
            type: "4",
            url: "",
          },
        ];

  const onChangeKey = (key) => {
    setActiveKey([Number(key[0])]);
    rightContentRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // 可选，平滑滚动效果
    });
    window.scrollTo({
      top: 530,
      left: 0,
      behavior: "smooth", // 可选，平滑滚动效果
    });
  };

  const onChange = (checkedValues) => {
    // console.log("checked = ", checkedValues);
    setChoseId(checkedValues);
  };

  const options = [
    {
      data_count: "6G",
      desc: "转录组测序是基于二代测序平台，研究特定细胞在某种功能状态下所能转录出来的所有RNA总和。转录组测序技术在检测基因表达变化的同时，还能发现新转录本，识别可变剪切和鉴定融合基因，广泛应用于临床癌症、复杂疾病和药物研发等领域。",
      id: 1,
      name: "普通真核转录组测序",
      platform: "MGI-T7",
      price: "249",
      type: 1,
      label: "普通真核转录组测序",
      value: 1,
    },
    {
      data_count: "10G",
      desc: "LncRNA高通量测序，采用去核糖体链特异性建 库方法，对长链非编码 RNA、mRNA 、环状RNA 等大RNA进行测序研究，从而快速全面准确地获 得与特定生物学过程（例如发育、疾病等）所有 大RNA转录本数据信息，可应用于细胞分化和发 育的研究、调控机理的研究、疾病标志物的寻 找、疾病的分子诊断、基因药物的研发等。",
      id: 2,
      name: "长链非编码LncRNA测",
      platform: "MGI-T7",
      price: "1080",
      type: 1,
      label: "长链非编码LncRNA测",
      value: 2,
    },
  ];
  const items = [
    {
      key: 1,
      label: (
        <>
          <div data-type="1" className="active-show"></div>转录组
        </>
      ),
      children: options,
    },
  ];
  const [hash, setHash] = useState(location.hash.substring(1));
  const [arr, setArr] = useState(items);
  const [typeArr, setTypeArr] = useState(options);
  const [activeKey, setActiveKey] = useState(
    [Number(location?.hash?.substring(1))] || [1]
  );
  const [choseId, setChoseId] = useState([1]);
  // console.log("activeKey", activeKey);
  // console.log("arr", arr);
  // console.log("typeArr", typeArr);
  // console.log("choseId", choseId);
  useEffect(() => {
    setHash(location.hash.substring(1));
    setActiveKey([Number(location.hash.substring(1))]);
  }, [location]);

  useEffect(() => {
    if (
      !!ProductTypeList &&
      ProductTypeList.length > 0 &&
      StandardAnalysisInfo.length > 0
    ) {
      let newTypeArr = StandardAnalysisInfo?.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      setTypeArr(newTypeArr);
      let newArr = ProductTypeList?.map((item1) => {
        // 使用filter方法找到arr2中所有type与item1.id匹配的项
        item1.label = (
          <>
            <div data-type={item1.id} className="active-show"></div>
            {item1.name}
          </>
        );
        item1.key = item1.id;
        const matchedItems =
          newTypeArr?.filter((item2) => item2.type === item1.id) || [];
        // 如果有匹配项，则将它们添加到当前item1的children属性中
        if (matchedItems.length > 0) {
          item1.children = matchedItems;
        }
        return item1;
      });
      setArr([...newArr]);
    }
  }, [hash, ProductTypeList]);
  useEffect(() => {
    initChooseID();
  }, [arr]);

  useEffect(() => {
    const newarr = arr?.filter((item) => {
      return Number(item.id) === Number(activeKey[0]);
    });

    let newchoosed = newarr?.length > 0 ? newarr[0]?.children[0].id : 1;
    setChoseId([newchoosed]);
  }, [activeKey]);

  const initChooseID = () => {
    const newarr = arr?.filter((item) => {
      return Number(item.id) === Number(hash);
    });

    let newchoosed = newarr?.length > 0 ? newarr[0]?.children[0].id : 1;
    setChoseId([newchoosed]);
  };

  return (
    <MainWrapper>
      <CommonBanner
        info={bannerInfo[0]}
        titleStyle={{
          marginBottom: "20px",
        }}
        decStyle={{ fontSize: "40px", lineHeight: "56px" }}
      ></CommonBanner>
      <Wrapper>
        <ModuleTitle titleZh="我要测序" titleEn="Standard Sequencing" />
        <div className="main-content u-f">
          <div className="standardcollapse-left-content">
            {arr?.map((item, index) => {
              return (
                <Collapse
                  activeKey={activeKey}
                  onChange={onChangeKey}
                  key={item.key}
                  accordion={true}
                >
                  {
                    <Collapse.Panel header={item.label} key={item.key}>
                      <Checkbox.Group
                        className="standard-check-x"
                        value={choseId}
                        onChange={onChange}
                      >
                        <div className="u-f-d">
                          {item?.children
                            ? item?.children.map((it, idx) => {
                                return (
                                  <Checkbox
                                    value={it.value}
                                    key={it.value}
                                    title={it.label}
                                  >
                                    {it.label}
                                  </Checkbox>
                                );
                              })
                            : null}
                        </div>
                      </Checkbox.Group>
                    </Collapse.Panel>
                  }
                </Collapse>
              );
            })}
          </div>
          <div className="right-content-x" ref={rightContentRef}>
            {choseId.length > 0 ? (
              typeArr
                .filter((item) => choseId.includes(item.id))
                .map((item, index) => {
                  return <RightItem item={item} key={item.id} />;
                })
            ) : (
              <Empty description={false} />
            )}
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
});
