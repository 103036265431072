import { memo, useState } from "react";

import { Button, Form, Input, Select, message } from "antd";
import CustomNumericInput from "./CustomNumericInput";
import ModalWrapper from "components/ModalWrapper";

import { validatePhone } from "@/constants/util";

import apis from "@/services/apis";
import { post } from "@/services/request";

import { Wrapper, ModalView } from "./style";

import SubsucImg from "@/assets/images/production/subsuc.png";

export default memo(({ item }) => {
  const { id, desc, platform, name, price, data_count, type } = item;
  console.log(item);
  const [form] = Form.useForm();

  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    if (loading) return;
    // console.log(values);
    setLoading(true);
    const { count: curcount, name: curname, mobile } = values;
    try {
      const res = await post(apis.postApply, {
        price,
        count: !curcount ? 1 : curcount,
        data_count,
        name: curname,
        mobile,
        product_detail_id: id,
      });

      if (res?.code === 1) {
        setIsModalOpen(true);
      } else {
        message.error(res?.msg || "请求出错");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setIsShow(false);
  };
  return (
    <Wrapper>
      <div className="right-content-top">
        <div className="title-x u-f-ac">
          <div className="primary-title">{name}</div>
          {type !== 5 && (
            <div className="primary-price">
              {price !== "定制" ? "￥" + (price || "--") : price || "--"}
            </div>
          )}
        </div>
        <div className="primary-dec">{desc}</div>
        {isShow && type !== 4 && type !== 5 && (
          <div className="primary-plant">测序平台：{platform || "--"}</div>
        )}
        {!isShow && (
          <div className="btn-container u-f">
            <Button
              type="primary"
              onClick={() => setIsShow(true)}
              className="btn-x"
            >
              点击下单
            </Button>
          </div>
        )}
      </div>
      {isShow && (
        <div className="right-content-bottom">
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <div className="u-f inline-view">
              {type !== 5 && (
                <Form.Item
                  style={{ marginRight: "60px", width: "220px" }}
                  label="单价"
                  name="price"
                >
                  <Input
                    defaultValue={price !== "定制" ? price + "元" : price}
                    disabled={true}
                  />
                </Form.Item>
              )}

              <Form.Item
                style={{ marginRight: "60px", width: "220px" }}
                label="样本数量"
                name="count"
              >
                <CustomNumericInput
                  min="1"
                  max="1000"
                  step={1}
                  defaultValue={1}
                />
              </Form.Item>
              <Form.Item
                name="data_count"
                label="数据量"
                style={{ width: "220px" }}
              >
                <Input defaultValue={data_count} disabled={type !== 5} />
              </Form.Item>
            </div>
            <div className="u-f inline-view">
              <Form.Item
                style={{ marginRight: "60px", width: "220px" }}
                label="姓名"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "请输入姓名",
                  },
                ]}
              >
                <Input placeholder="请输入姓名（必填）" />
              </Form.Item>
              <Form.Item
                label="联系方式"
                name="mobile"
                style={{ marginRight: "85px", width: "284px" }}
                rules={[
                  {
                    required: true,
                    validator: validatePhone,
                  },
                ]}
              >
                <Input placeholder="请输入联系方式（必填）" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-x btn-submit"
                >
                  提交需求
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
      <ModalWrapper open={isModalOpen} onOk={handleOk} onCancel={handleOk}>
        <ModalView>
          <div className="u-f-jc">
            <img src={SubsucImg} alt="" />
          </div>
          <div className="title-x">提交成功！</div>
          <div>
            稍后基预生信的工作人员
            <br />
            和您对接详细的服务方案
          </div>
          <div className="dec-x">请留意您所填的联系方式！</div>
        </ModalView>
      </ModalWrapper>
    </Wrapper>
  );
});
