import styled from "styled-components";
import Bg from "@/assets/images/production/custom_bg.png";
import Tri from "@/assets/images/production/custom_icon6.png";

export const Wrapper = styled.div`
  width: 1200px;
  padding: 50px 0;
  margin: 0 auto;
  .sub-dec {
    margin-top: 44px;
    text-align: center;
    font-size: 25px;
    color: #333333;
    line-height: 36px;
  }
`;
export const MainView = styled.div`
  width: 100%;
  height: 330px;
  padding-top: 50px;
  padding-bottom: 44px;
  margin-bottom: 50px;
  box-sizing: border-box;
  background: url(${Bg}) center center / cover no-repeat;
  .main-x {
    width: 1200px;
    margin: 0 auto;
    align-items: flex-end;

    .main-item-icon {
      width: 42px;
      height: 50.5px;
      margin-bottom: 13px;
      background: url(${Tri}) center center / cover no-repeat;
    }
  }
`;
export const MainItem = styled.div`
  height: 232px;
  img {
    width: 120px;
    height: 120px;
    margin-bottom: 44px;
  }
  .dec {
    font-size: 25px;
    color: #60569e;
    line-height: 36px;
    text-align: center;
  }
`;
