import React from "react";
import { createBrowserRouter } from "react-router-dom";
import HomePage from "@/pages/HomePage/index";
import AboutUsPage from "@/pages/AboutUsPage";
import CooperationPage from "@/pages/CooperationPage";
import JoinUsPage from "@/pages/JoinUsPage";
import ConcactUsPage from "@/pages/ConcactUsPage";
import ProductionPage from "@/pages/ProductionPage";
import ProductionHomePage from "@/pages/ProductionPage/ProductionHomePage";
import ProductionCustombioanalysis from "@/pages/ProductionPage/ProductionCustombioanalysis";
import ProductionOneStopSackage from "@/pages/ProductionPage/ProductionOneStopSackage";
import ProductionStandardPureSequence from "@/pages/ProductionPage/ProductionStandardPureSequence";
import NotFoundPage from "@/pages/404.js";
import App from "../_App";

export const urlBasename = "/";
export default createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "/",
          index: true,
          element: <HomePage />,
        },
        {
          path: "production",
          element: <ProductionPage />,
          children: [
            {
              path: "home",
              element: <ProductionHomePage />,
            },
            {
              path: "custombioanalysis",
              element: <ProductionCustombioanalysis />,
            },
            {
              path: "onestopsackage",
              element: <ProductionOneStopSackage />,
            },
            {
              path: "standardpuresequence",
              element: <ProductionStandardPureSequence />,
            },
          ],
        },
        {
          path: "/cooperation",
          element: <CooperationPage />,
        },
        {
          path: "/joinus",
          element: <JoinUsPage />,
        },
        {
          path: "/about",
          element: <AboutUsPage />,
        },
        {
          path: "/concact",
          element: <ConcactUsPage />,
        },

        {
          path: "*",
          element: <NotFoundPage />,
        },
      ],
    },
  ],
  {
    basename: urlBasename,
  }
);
