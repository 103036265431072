import React, { memo } from "react";
import styled from "styled-components";
import { MainWrapper } from "components/common/Wrapper";
import _404Img from "@/assets/images/common/404.png";
import { Link } from "react-router-dom";

export const Main = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1440px;
  text-align: center;
  font-weight: 400;
  .content {
    position: relative;
    width: 630px;
    max-width: 100vw;
    height: 210px;
  }
  .content::after {
    position: absolute;
    left: 0;
    bottom: -7px;
    width: 100%;
    font-size: 24px;
    color: #666;
    content: "抱歉，页面无法访问";
  }
  .c-404-img {
    width: 100%;
  }
  .go-home {
    width: 140px;
    border: 1px solid #60569e;
    border-radius: 6px;
    font-size: 16px;
    line-height: 40px;
    color: #60569e;
    margin: 40px auto 0;
    display: block;
    transition: color 0s;
  }
  .go-home:hover {
    background-color: #60569e;
    color: #fff;
    border-color: #fff;
  }
`;

// NOTE: 路径匹配失败时，跳转到此页面
export default memo(function NotFoundPage({ route }) {
  return (
    <MainWrapper>
      <Main>
        <div className="content">
          <img src={_404Img} alt="" className="c-404-img" />
        </div>
        <Link to={"/"} className="go-home">
          返回首页
        </Link>
      </Main>
    </MainWrapper>
  );
});
