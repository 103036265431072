import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Form, Input, Select, message } from "antd";
import ModalWrapper from "components/ModalWrapper";

import { validatePhone } from "@/constants/util";

import apis from "@/services/apis";
import { post } from "@/services/request";

import styled from "styled-components";

import { JoinUsForm } from "@/pages/JoinUsPage/style";

import Logo from "@/assets/images/common/logo.png";

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 50px;
`;
const BtnView = styled.div`
  width: 1200px;
  margin: 0 auto;
  .btn {
    width: 250px;
    height: 78px;
    background: #333333;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    font-weight: 400;
    font-size: 25px;
    color: #ffffff;
    line-height: 78px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background: #60569e;
    }
    &.btn-2 {
      width: 186px;
      margin-left: 78px;
    }
  }
`;
const { Option } = Select;
export default memo(() => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    if (loading) return;
    // console.log(values);
    setLoading(true);
    try {
      const res = await post(apis.postDemand, { ...values });

      if (res?.code === 1) {
        message.success(res?.msg || "请求成功");
        handleOk();
      } else {
        message.error(res?.msg || "请求出错");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <Wrapper>
      <BtnView className="u-f-ajc">
        <div className="btn btn-1" onClick={() => navigate("/cooperation")}>
          了解相关合作案例
        </div>
        <div className="btn btn-2" onClick={() => setIsModalOpen(true)}>
          填写需求
        </div>
      </BtnView>
      <ModalWrapper open={isModalOpen} onOk={handleOk} onCancel={handleOk}>
        <JoinUsForm className="u-f-d u-f-ac">
          <img src={Logo} alt="" className="logo-x" />
          <div className="form-x">
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <div className="u-f inline-view">
                <Form.Item
                  style={{ marginRight: "32px" }}
                  label="姓名"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "请输入姓名",
                    },
                  ]}
                >
                  <Input placeholder="请输入姓名（必填）" />
                </Form.Item>
                <Form.Item
                  name="workplace"
                  label="单位"
                  rules={[
                    {
                      required: true,
                      message: "请输入单位",
                    },
                  ]}
                >
                  <Input placeholder="请输入单位（必填）" />
                </Form.Item>
              </div>
              <Form.Item
                label="联系方式"
                name="mobile"
                rules={[
                  {
                    required: true,
                    validator: validatePhone,
                  },
                ]}
              >
                <Input placeholder="请输入联系方式（必填）" />
              </Form.Item>
              <Form.Item
                label="需求类型"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "请选择需求类型",
                  },
                ]}
              >
                <Select placeholder="请选择" allowClear>
                  <Option value="定制化生物分析或建模">
                    定制化生物分析或建模
                  </Option>
                  <Option value="整包课题项目研究服务">
                    整包课题项目研究服务
                  </Option>
                </Select>
              </Form.Item>
              <div className="u-f-jc">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                  >
                    提交信息
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </JoinUsForm>
      </ModalWrapper>
    </Wrapper>
  );
});
