import React, { memo, useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
import router from "@/router";
import PageLoading from "components/common/PageLoading";

export default function App() {
  return (
    <StyleProvider
      hashPriority="high"
      transformers={[legacyLogicalPropertiesTransformer]}
    >
      <RouterProvider router={router} fallbackElement={<PageLoading />} />
    </StyleProvider>
  );
}
