import { memo } from "react";
import { Helmet } from "react-helmet";

export default memo(({ pathName }) => {
  const PAGE_DESCRIPTION = "基预生信 助力科研价值深度挖掘";
  const SEO_DESCRIPTION =
    "基预生信平台致力于成为国内质量最高的一站式科研服务平台。平台通过整合国内外高校、科研院所、检测机构以及生物技术企业的科研资源，拥有全国最具规模且专业程度最高的生物信息科学家/工程师队伍，以及最具性价比的科研服务价格。可轻松实现客户项目的精准匹配，让离你研究方向最近的科研团队为你服务。平台业务贯通生命科学研究全领域，为全国乃至全球的科学家和企业家提供高质量的科研服务。";
  const SEO_KEYWORD =
    "基预生信,基预科技,基预健康,基预实验室,疾病预防,健康管理,基因检测,健康企业,基预医学,基预科研,肺癌七抗,APOE检测,单细胞测序,m6a甲基化测序,心理测评,健康鼠标,体征检测,健康数字大屏,数字化健康系统,个性化体检,健康膳食推荐,单细胞捕获测序平台,纳米孔测序";
  const WEB_ICO = "https://www.genepre.com/favicon.ico";
  const getPageTitle = () => {
    switch (pathName) {
      case "/":
        return "首页";

      case "/production/home":
        return "产品中心";
      case "/production/custombioanalysis":
        return "定制化生信分析";
      case "/production/onestopsackage":
        return "一站式整包项目";
      case "/production/standardpuresequence":
        return "标准分析";

      case "/cooperation":
        return "合作案例";

      case "/joinus":
        return "加入我们";

      case "/about":
        return "关于基预";

      case "/concact":
        return "联系我们";

      default:
        return "基预官网";
    }
  };

  return (
    <Helmet>
      <title>{getPageTitle() + "-" + PAGE_DESCRIPTION}</title>
      {/* <meta name="description" content={SEO_DESCRIPTION} />
      <meta name="keywords" content={SEO_KEYWORD} /> */}
      {/* <meta name="viewport" content="width=375,user-scalable=no" /> */}
      {/* <link rel="icon" href={WEB_ICO} /> */}
    </Helmet>
  );
});
