import { memo } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import "./index.css";

import ModalBg from "@/assets/images/common/modal_bg.png";

const ModalView = styled(Modal)`
  width: 1200px !important;
  height: 700px;
  padding-bottom: 0;
  background: #ffffff;
  box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.16);
  .left-x {
    width: 600px;
    height: 700px;
    background: url(${ModalBg}) center center / cover no-repeat;
  }
`;

export default memo(({ open, onOk, onCancel, children }) => {
  return (
    <ModalView
      className="common-modal-wrapper"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
    >
      <div className="left-x"></div>
      <div className="right-x">{children}</div>
    </ModalView>
  );
});
