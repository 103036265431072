import React, { memo } from "react";
import { Link } from "react-router-dom";
import ModuleTitle from "components/ModuleTitle";
import { FooterView } from "./style";

import ermImg1 from "@/assets/images/common/ewm1.png";
import ermImg2 from "@/assets/images/common/ewm2.png";
import ermImg3 from "@/assets/images/common/ewm3.jpg";
import iconImg1 from "@/assets/images/common/icon_tel.png";
import iconImg2 from "@/assets/images/common/icon_adr.png";
import iconImg3 from "@/assets/images/common/icon_web.png";
import iconImg4 from "@/assets/images/common/icon_mail.png";

const subdecArr = [
  {
    dec: "联系电话：028-89892883",
    icon: iconImg1,
    size: ["23.7px", "24px"],
  },
  {
    dec: "地址：四川省成都市高新区孵化园1号楼A栋2楼21号",
    icon: iconImg2,
    size: ["22px", "26px"],
  },
  {
    dec: "网址：www.genepre.com",
    icon: iconImg3,
    size: ["29px", "24px"],
  },
  {
    dec: "企业邮箱：project@genepre.com",
    icon: iconImg4,
    size: ["24px", "22px"],
  },
];

// NOTE: 页面的命名尽量以 Page 结尾，区分于组件（建议）
export default memo(function Footer() {
  const navitems = [
    [
      {
        title: "首页",
        path: "/",
      },
    ],
    [
      {
        title: "产品中心",
        path: "/production/home",
      },
      {
        title: "个性化分析服务",
        path: "/production/home",
      },
      {
        title: "测序检测服务",
        path: "/production/standardpuresequence",
      },
    ],
    [
      {
        title: "合作案例",
        path: "/cooperation",
      },
      {
        title: "转录组",
        path: "/cooperation",
      },
      {
        title: "基因组",
        path: "/cooperation",
      },
      {
        title: "微生物组",
        path: "/cooperation",
      },
      {
        title: "蛋白代谢组",
        path: "/cooperation",
      },
    ],
    [
      {
        title: "加入我们",
        path: "/joinus",
      },
      {
        title: "工程师团队",
        path: "/joinus#intromain",
      },
      {
        title: "项目管理团队",
        path: "/joinus#intromain",
      },
      {
        title: "市场推广团队",
        path: "/joinus#intromain",
      },
    ],
    [
      {
        title: "关于基预",
        path: "/about",
      },
      {
        title: "团队介绍",
        path: "/about",
      },
      {
        title: "工程师团队",
        path: "/about#bottom",
      },
    ],
    [
      {
        title: "联系我们",
        path: "/concact",
      },
    ],
  ];

  const ewmArr = [
    {
      title: "科研助手-小蓝",
      path: ermImg1,
    },
    {
      title: "科研助手-小白",
      path: ermImg2,
    },
    {
      title: "公众号",
      path: ermImg3,
    },
  ];

  return (
    <FooterView>
      {/* <ModuleTitle titleZh="联系我们" titleEn="Contact us" /> */}
      {/* <div className="hor-line"></div> */}
      <div className="main-content">
        {navitems.map((item, index) => {
          return (
            <div className="link-v-x" key={index}>
              {item.map((it, idx) => {
                return (
                  <div className="link-item" key={it.title}>
                    <Link to={it.path}>{it.title}</Link>
                  </div>
                );
              })}
            </div>
          );
        })}
        {ewmArr.map((item, index) => {
          return (
            <div className="ewm-x" key={item.title}>
              <img src={item.path} alt={item.title} />
              {item.title}
            </div>
          );
        })}
        <div className="dec-x">
          {subdecArr.map((item, index) => {
            return (
              <div className="dec-item-x" key={item.dec}>
                <img
                  src={item.icon}
                  alt={item.dec}
                  width={item.size[0]}
                  height={item.size[1]}
                />
                {item.dec}
              </div>
            );
          })}
        </div>
      </div>
      <div className="copyright">
        Copyright © 2020~2024 JIYu Tec. All Rights Reserved.
        <a href="https://beian.miit.gov.cn/" target="_blank">
          蜀ICP备2020032696号-1
        </a>
        川公网安备 51010702002152号
      </div>
    </FooterView>
  );
});
