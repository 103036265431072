import styled from "styled-components";

export const FooterView = styled.div`
  position: relative;
  padding: 50px 5.2% 33px;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 16px;
  background: #f0f1f5;
  min-width: 1200px;
  box-sizing: border-box;
  @media screen and (max-width: 1400px) {
    padding: 50px 1% 33px;
  }
  .hor-line {
    margin-top: 47px;
    margin-bottom: 53px;
    width: 100%;
    height: 1px;
    background-color: #d6d8dc;
  }
  .main-content {
    display: flex;
    justify-content: space-evenly;
    .link-v-x {
      .link-item {
        margin-bottom: 18px;
        &:first-child {
          margin-bottom: 29px;
        }
      }
    }
    .ewm-x {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: #242039;
      line-height: 20px;
      img {
        width: 120px;
        height: 120px;
        margin-bottom: 18px;
        @media screen and (max-width: 1400px) {
          width: 80px;
          height: 80px;
        }
      }
    }
    .dec-x {
      .dec-item-x {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        img {
          margin-right: 23px;
          @media screen and (max-width: 1400px) {
            margin-right: 12px;
          }
        }
      }
    }
  }
  .copyright {
    margin-top: 64px;
    font-size: 14px;
    text-align: center;
  }
`;
