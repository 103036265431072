import React, { memo, useEffect, useState } from "react";
import { Outlet, useLocation, redirect, useNavigation } from "react-router-dom";
import PageLoading from "components/common/PageLoading";
import HeaderHelmet from "components/common/HeaderHelmet";
import NavBar from "components/common/NavBar";
import Footer from "components/common/Footer";
import { Popover, message } from "antd";

import { getStorage, setStorage } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";
import apis from "@/services/apis";
import { get } from "@/services/request";
export default function App() {
  const navigation = useNavigation();
  const location = useLocation();

  const [StandardAnalysisInfo, setStandardAnalysisInfo] = useState(null);
  const [BannerList, setBannerList] = useState(null);
  const [ProductTypeList, setProductTypeList] = useState(null);
  // console.log("StandardAnalysisInfo", StandardAnalysisInfo);

  useEffect(() => {
    if (
      !getStorage("StandardAnalysisInfo") ||
      !getStorage("BannerList") ||
      !getStorage("ProductTypeList")
    ) {
      getBasicInfo();
    } else {
      setStandardAnalysisInfo(getStorage("StandardAnalysisInfo"));
      setBannerList(getStorage("BannerList"));
      setProductTypeList(getStorage("ProductTypeList"));
    }
  }, []);
  // 获取我要测序的所有组别信息
  const getBasicInfo = async () => {
    try {
      const res = await get(apis.getProductDetailList);
      const res1 = await get(apis.getBannerList);
      const res2 = await get(apis.getProductTypeList);
      // console.log(res);
      if (res?.code === 1 && !!res?.data) {
        setStorage("StandardAnalysisInfo", res.data);
        setStandardAnalysisInfo(res.data);
      } else {
        message.error(res?.msg || "请求出错");
      }
      if (res1?.code === 1 && !!res1?.data) {
        setStorage("BannerList", res1.data);
        setBannerList(res1.data);
      } else {
        message.error(res1?.msg || "请求出错");
      }
      if (res2?.code === 1 && !!res2?.data) {
        setStorage("ProductTypeList", res2.data);
        setProductTypeList(res2.data);
      } else {
        message.error(res2?.msg || "请求出错");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _value = {
    ProductTypeList,
    BannerList,
    StandardAnalysisInfo,
  };
  // console.log(_value);
  return (
    <modalContext.Provider value={_value}>
      <HeaderHelmet pathName={location.pathname} />
      <NavBar />
      <></>
      {navigation.state === "loading" ? <PageLoading /> : <Outlet />}
      <Footer />
    </modalContext.Provider>
  );
}
