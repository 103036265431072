import React, { memo, useRef, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CommonBanner from "components/CommonBanner";
import ModuleTitle from "components/ModuleTitle";
import { MainWrapper } from "components/common/Wrapper";

import { getBannerInfo } from "@/constants/util";
import { modalContext } from "@/constants/modalContext";

import { Wrapper, CustomizedItem, StandardItem } from "./style";

import DefaultBannerImg from "@/assets/images/production/banner.png";
import CBg1 from "@/assets/images/production/cbg1.png";
import CBg1Active from "@/assets/images/production/cbg1_active.png";
import CBg2 from "@/assets/images/production/cbg2.png";
import CBg2Active from "@/assets/images/production/cbg2_active.png";
import TriImg from "@/assets/images/home/tri.png";

import BtIcon1 from "@/assets/images/production/bticon1.png";
import BtIcon2 from "@/assets/images/production/bticon2.png";
import BtIcon3 from "@/assets/images/production/bticon3.png";
import BtIcon4 from "@/assets/images/production/bticon4.png";
import BtIcon5 from "@/assets/images/production/bticon5.png";
import BtIconActive1 from "@/assets/images/production/bticon1_active.png";
import BtIconActive2 from "@/assets/images/production/bticon2_active.png";
import BtIconActive3 from "@/assets/images/production/bticon3_active.png";
import BtIconActive4 from "@/assets/images/production/bticon4_active.png";
import BtIconActive5 from "@/assets/images/production/bticon5_active.png";

export default memo(function ProductionHomePage() {
  const navigate = useNavigate();
  const { BannerList } = useContext(modalContext);

  const storageBannerInfo = getBannerInfo(2, BannerList);

  const bannerInfo =
    storageBannerInfo.length > 0
      ? storageBannerInfo
      : [
          {
            desc: "让服务匹配更精准，让科学研究更轻松",
            img_url: DefaultBannerImg,
            title: "基预生信",
            type: "2",
            url: "",
          },
        ];
  const infoArr = [
    {
      key: "1",
      title: "定制化生信分析",
      dec: "根据您的需求为您精准匹配<br/>工程师",
      url: "/production/custombioanalysis",
      icon: CBg1,
      iconactive: CBg1Active,
    },
    {
      key: "2",
      title: "一站式整包项目",
      dec: "根据您的课题思路为您匹配导师团队，<br/>输出专业方案",
      url: "/production/onestopsackage",
      icon: CBg2,
      iconactive: CBg2Active,
    },
  ];
  const btArr = [
    {
      id: 1,
      title: "转录组",
      url: "/production/standardpuresequence#1",
      icon: BtIcon1,
      iconactive: BtIconActive1,
    },
    {
      id: 2,
      title: "基因组",
      url: "/production/standardpuresequence#2",
      icon: BtIcon2,
      iconactive: BtIconActive2,
    },
    {
      id: 3,
      title: "微生物组",
      url: "/production/standardpuresequence#3",
      icon: BtIcon3,
      iconactive: BtIconActive3,
    },
    {
      id: 4,
      title: "蛋白代谢组",
      url: "/production/standardpuresequence#4",
      icon: BtIcon4,
      iconactive: BtIconActive4,
    },
    {
      id: 5,
      title: "纯测序",
      url: "/production/standardpuresequence#5",
      icon: BtIcon5,
      iconactive: BtIconActive5,
    },
  ];

  return (
    <MainWrapper>
      <CommonBanner
        info={bannerInfo[0]}
        titleStyle={{
          marginBottom: "20px",
        }}
        decStyle={{ fontSize: "40px", lineHeight: "56px" }}
      ></CommonBanner>
      <Wrapper>
        <div className="top-dec">
          基预团队始终专注生物信息学分析服务，以多领域NGS标准测序分析为基础，搭建了涉及几乎全部生命科学研究领域的海量分析工程师储备资源，对全领域研究者的生信分析需求提供一对一的精准解决方案
        </div>
        <ModuleTitle titleZh="我要分析" titleEn="Customized Analysis" />
        <div className="customized-analysis-x u-f-jsb">
          {infoArr.map((item) => {
            const { key, title, dec, url, icon, iconactive } = item;
            return (
              <CustomizedItem icon={icon} iconactive={iconactive} key={key}>
                <div className="title-x">{title}</div>
                <div
                  className="dec-x"
                  dangerouslySetInnerHTML={{ __html: dec }}
                ></div>
                <div
                  className="more-btn u-f-ac u-f-jsb"
                  onClick={() => navigate(url)}
                >
                  了解更多
                  <img src={TriImg} alt="" />
                </div>
              </CustomizedItem>
            );
          })}
        </div>
        <ModuleTitle titleZh="我要测序" titleEn="Standard Sequencing" />
        <div className="bt-x u-f-jsb">
          {btArr.map((item) => {
            const { id, title, url, icon, iconactive } = item;
            return (
              <StandardItem
                key={id}
                className="u-f-d u-f-ac"
                icon={icon}
                iconactive={iconactive}
                onClick={() => navigate(url)}
              >
                <div className="img-x"></div>
                {title}
              </StandardItem>
            );
          })}
        </div>
      </Wrapper>
    </MainWrapper>
  );
});
