import styled from "styled-components";

export const Wrapper = styled.div`
  width: 1100px;
  padding: 50px 0;
  margin: 0 auto;
  box-sizing: border-box;

  .coopration-btn-x {
    justify-content: flex-end;
    .coopration-btn {
      width: 120px;
      height: 36px;
      border-radius: 18px;
      background: #333;
      font-size: 20px;
      color: #f7f8fb;
      line-height: 36px;
      text-align: center;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background: #60569e;
      }
    }
  }
  .coopration-item {
    width: 100%;
    height: 240px;
    margin-bottom: 50px;
    background: #f7f8fb;
    img {
      width: 300px;
      height: 240px;
    }
    .coopration-content-x {
      flex: 1;
      padding-left: 50px;
      padding-top: 36px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 20px;
      color: #595959;
      line-height: 30px;

      .primary-title-x {
        margin-bottom: 36px;
        font-weight: bold;
        font-size: 25px;
        color: #60569e;
        line-height: 36px;
        .tag-x {
          min-width: 84px;
          height: 29px;
          padding: 0 12px;
          margin-right: 11px;
          text-align: center;
          background: #60569e;
          border-radius: 5px;
          line-height: 29px;
          font-weight: 400;
          font-size: 20px;
          color: #ffffff;
          line-height: 29px;
        }
      }
    }
  }
`;
export const MoreCooprationView = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 100px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  line-height: 24px;
  img.logo-x {
    width: 286px;
    height: 74px;
    margin-bottom: 100px;
  }
  .primary-title {
    margin-bottom: 45px;
    font-size: 40px;
    line-height: 56px;
  }
  .info-x {
    width: 100%;
    padding-left: 39px;
    padding-right: 56px;
    box-sizing: border-box;
    .info-item {
      width: 145px;
      .title-x {
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 17px;
        color: #333333;
        line-height: 24px;
      }
    }
  }
`;
